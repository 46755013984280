.ObjectHeader {
    // position: relative;
    // top: 0;
    width: 100%;

    .navbar {
        border-radius: 0% 0% 7% 7%;
        //margin-bottom: 10px;
    }

    .nameUser {
        font-size: 14px;
        background-color: #fff;
        border-radius: 80px 0px 0px 80px;
        padding: 5px 25px 5px 12px;
        margin-top: -10px;
    }

    .nameUser i {
        transition: transform 300ms;
    }

    .nameUser i.rotate-minus-90 {
        transform: rotate(-180deg);
    }

    .userIcon {
        // z-index: 99998;
        position: absolute;
        left: 12%;
        top: -18px;
    }

    .navbar-menu {
        margin-left: 1rem;
    }

    .navbar-title {
        width: 100%;
        margin-left: 1rem;
    }

    .navbar-offline {
        right: 4rem;
        position: absolute;
        top: 1.1rem;
    }

    .imgNoSignal {
        filter: invert(30%) sepia(84%) saturate(1565%) hue-rotate(329deg) brightness(87%) contrast(98%);
    }

    .count-offline {
        position: relative;
        top: 0.5rem;
        right: 0.5rem;
        background-color: white;
        padding: 1px 8px;
        border-radius: 50%;
        font-size: 10px;
        // color: red;
        font-weight: bold;
    }

    .navbar-profile {
        left: 0rem;
        right: 0;
        position: absolute;
        top: 2.4rem;
    }

    .menuIcon {
        position: absolute;
        top: 5rem;
        left: -1rem;
        margin: 0;
        // right: 0;
        background-color: #fff;
        box-shadow: 0 0 20px 0 #636363;
        border-radius: 10px;
        font-size: 14px;
        list-style: none;
        transition: 0.5s;
        visibility: hidden;
        opacity: 0;
        z-index: 99;
    }

    .menuIcon.active {
        top: 4.5rem;
        left: 1rem;
        // right: 1rem;
        visibility: visible;
        opacity: 1;
    }

    .menuIcon::before {
        z-index: 99999;
        content: "";
        position: absolute;
        top: -7px;
        left: 25px;
        width: 20px;
        height: 20px;
        background: #fff;
        transform: rotate(45deg);
    }

    .menuIcon ul li {
        list-style: none;
        padding: 10px 0;
        border-top: 1px solid rgb(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .menuIcon ul {
        padding-left: 1rem !important;
        margin-bottom: 0rem !important;
    }

    .menuIcon ul li a {
        display: inline-block;
        text-decoration: none;
        color: #555;
        font-weight: 500;
        transition: 0.5s;
        padding-top: 5px;
        padding-right: 10px;
    }

    .menuIcon ul li:hover a {
        color: #ff5d94;
    }
    .menuIcon h3 {
        width: 100%;
        text-align: center;
        font-size: 18px;
        padding: 20px 0px 1px;
        font-weight: 500;
        color: #555;
    }

    .menuIcon h3 span {
        font-size: 12px;
        color: #cecece;
        font-weight: 400;
    }

    .nameUserMobile {
        margin-bottom: 0.5rem !important;
        font-size: 15px;
        font-weight: 500;
        opacity: 0;
        display: none !important;
    }

    .btn-login {
        position: absolute;
        right: 4px;
        top: 4px;
    }

    // resposive styles
    @media screen and (max-width: 586px) {
        .nameUser {
            display: none !important;
        }

        .navbar-profile {
            right: 3.5rem;
        }
        .menuIcon {
            //   left: 34%;
            //   width: 63%;
        }
        .menuIcon::before {
            // right: 12px;
        }
        .nameUserMobile {
            opacity: 1;
            display: inline !important;
        }

        .userIcon {
            left: 10%;
        }
    }
}

.offcanvas-backdrop.show {
    opacity: 0.2;
  }
  
  .offcanvas.offcanvas-start {
    width: 250px;
    background-color: #01397d;
  }
  
  .offcanvas-header {
    // border-bottom: white solid 1px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  
  .header-container-sidebar {
    display: flex;
    width: 100%;
    padding: 10px 0px 9px 15px;
    border-bottom: #7f7d7d solid 1px;
    align-items: center;
    cursor: pointer;
  }
  
  .imgLogoSidebar {
    background-color: #c2c7d0;
    border-radius: 50%;
  }
  
  .spanNombreSistema {
    color: #c2c7d0;
    padding-left: 10px;
    font-size: 25px;
    font-weight: 600;
  }
  
  .spanNombreUsuario {
    color: #c2c7d0;
    padding-left: 10px;
    font-size: 12px;
    font-weight: 600 !important;
  }
  
  .spanDetalleUsuario {
    color: #c2c7d0;
    padding-left: 10px;
    font-size: 9px;
  }
  
  .offcanvas-body {
    padding-left: 0;
    padding-right: 0;
  }
  
  .body-container-sidebar {
    display: flex;
    width: 100%;
    padding: 12px 0px 12px 25px;
    border-bottom: #7f7d7d solid 1px;
    align-items: center;
    color: #c2c7d0;
    cursor: pointer;
  }
  
  .iconOpcion {
    font-size: 1.1rem;
  }
  
  .spanTextoOpcion {
    color: #c2c7d0;
    padding-left: 20px;
    font-size: 0.9rem;
    font-weight: 600;
  }
  