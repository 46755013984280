.PdfGeneratorPartesDiarios {
    .buttonFloating {
        width: 2.5rem;
        height: 2.5rem;
        bottom: 10px;
        padding: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: pulse 2s ease-in-out infinite;
        background-color: #FF0000;
    }

    i {
        font-size: 1.6rem;
        color: white;
    }

    @keyframes pulse {

        0%,
        100% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }
    }
}