.ElementModalCostume {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000000ad;
    z-index: 1059;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-modal {
        min-height: 250px;
        width: 90%;
        background: white;
        border-radius: 12px;
    }

    .header {
        padding: 0.7rem;
        border-bottom: 1px solid #ccc;

        p {
            margin-left: auto;
            cursor: pointer;
        }
    }

    .footer {
        padding: 0.5rem;
        border-top: 1px solid #ccc;
        display: flex;
        justify-content: flex-end
    }

    .body {
        height: 100%;
        min-height: 375px;
        padding: 15px;
        overflow-y: scroll;
        max-height: 28rem;
        position: relative;
    }
}