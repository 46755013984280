$primary: rgba(3,60,156,1);
$secondary: #00a3e0;
$success: #28a745;
$danger: #dc3545;

@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~bootstrap/scss/bootstrap";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";

// @import 'https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@200&display=swap';
@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback";

@import "../node_modules/rc-steps/assets/index.css";

body {
  margin: 0;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, html {
  scroll-behavior: smooth !important;
}

.custom-select, .accordion-button:focus { 
  z-index: auto !important;
}

.input-group .btn {
  z-index: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*COMMON*/
.cian-neon{
    color: #08F7FE;
}
.verde-neon{
    color: #39FF14;
}
.amarillo-neon{
    color: #FFFF00;
}
.rosa-neon{
    color: #FF2079;
}
.naranja-neon{
    color: #FFA500;
}
.gris-oscuro{
    color: #2C3E50;
}