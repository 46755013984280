.ButtonFloatingContainer{
    .buttonFloating {
        bottom: 10px;
        padding: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: pulse 2s ease-in-out infinite;
    }
    
    @keyframes pulse {
    
        0%,
        100% {
            transform: scale(1);
        }
    
        50% {
            transform: scale(1.1);
        }
    }
}