.bk-color-danger button {
  background-color: #df3b47;
  color: white;
}

.bk-color-warning button {
  background-color: #ffc415;
  color: black;
}

.bk-color-success button {
  background-color: #00a844;
  color: white;
}