.CardDetallePreLiquidacionContent {
    .titleTab {
      height: 70px;
      padding: 0 20px;
      border: 1px solid #cdcdcd;
    }
    .contentTab {
      padding: 20px;
      border: 1px solid #cdcdcd;
      background: #fff;
    }
}