.contenedor {
    .image-empty {
        min-height: 200px;
        border: solid 1px #ccc;

    }

    border: solid 1px #ccc;
    padding: 0.5rem;

    .imagenContenedor {
        border: 1px solid #ccc;
        margin-bottom: 0.5rem;
        border-radius: 0 0 0.3rem 0.3rem;
    }

}

.container-fluid {
    .search {
        bottom: 20px;
        right: 20px;
        padding: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: pulse 2s ease-in-out infinite;
    }

    @keyframes pulse {

        0%,
        100% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }
    }
}