.ElementItemCard {
    width: 100%;
    padding: 0.5rem;
    margin: 0 0 1rem 0;
    border-radius: 0.5rem;
    // background-color: #2C3E50;
    position: relative;
}

.spanIndice {
    position: absolute;
    font-weight: bold;
    top: 0.4rem;
    right: 0.4rem;
    background-color: #ffa500;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    text-align: center;
}