.ElementStepperContent {
    .titleTab {
      height: 50px;
      padding: 0 10px;
      border: 1px solid #cdcdcd;
    }
    .contentTab {
      padding: 10px;
      border: 1px solid #cdcdcd;
      background: #fff;
    }
}