.GridDetalleSalidaAlmacen {
    .rowColor {
      --bs-table-striped-bg: #e7ecff !important;
      border: 1px solid rgba(0, 0, 248, 0.432);
    }
    .rowStyle {
      padding-left: 50px; 
      font-size: 44px;
    }

    th {
      font-size: 14px;
    }
    td {
      font-size: 14px;
    }

    
}