.CardContainer {
}
.CardHeader {
  height: 40px;
  padding: 0 20px;
  border: 1px solid #cdcdcd;
  font-size: 18px;
}
.CardBody {
  padding: 20px;
  border: 1px solid #cdcdcd;
  background: #fff;
}
