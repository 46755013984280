.ElementListDashboardStockPersonalTotal {
  border-radius: 100px;
  box-shadow: 0 0 10px #333f50;
  margin: 20px;
  overflow: hidden;
  background-color: #333f50;
  .Content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .LabelContainer {
    width: 50px;
    height: fit-content;
    margin: 0;
    border-radius: 50%;
    background-color: #ffc000;
    border-color: #ffc000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 15px;
  }
  .LabelContainer::before{
    content: "";
    padding-top: 100%;
  }

  .LabelContent {
    text-align: center;
    // font-size: clamp(5px, 90%, 20px);
    font-size: 14px;
    font-weight: bold;
    color: #222a35;
    position: absolute;
  }

  .StockText{
    display: -webkit-box;
    align-items: center;
    margin-left:1.5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // Webkit-line-clamp: 2;
    // webkitBoxOrient: 'vertical';
    max-height: 2em
  }
  .ArrowContainer{
    margin: 0;
    border-radius: 100px;
    background-color: #333f50;
    border-color: #333f50;
    margin-left: auto;
  }
  .titleTab {
    height: 40px;
    padding: 0 20px;
    border: 1px solid #cdcdcd;
  }

  .contentTab {
    padding: 20px;
    border: 1px solid #cdcdcd;
    background: #fff;
    height: 300;
    overflow: "auto"
  }
}
