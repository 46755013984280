.ViewMasterHome {
    .page-content {
        margin-top: 30px;
        margin-bottom: 60px;
        padding: 1rem;
    }

    .btn-icon-first {
        min-width: 46px;
    }
}

.fondo {
  // position: relative;
  background: white;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.fondo:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.4;
  background-image: url("../../../../assets//img/backgroundLogin.jpg");
  background-position: center top;
  //background-size: cover;

  background-repeat: no-repeat;
  // background-position: 50% 0;
}
.contenedor-titulo {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}
