.ViewLogisticaHome {
    .page-content {
        margin-top: 30px;
        margin-bottom: 60px;
        padding: 1rem;
    }

    .btn-icon-first {
        min-width: 46px;
    }
}
